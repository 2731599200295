/* eslint-disable prettier/prettier */
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Col, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import FilterButton from '../../components/FilterButton';
import {
    reportType, setDateRange
} from '../../redux/features/downloadReport/downloadReportFilterSlice';

function DownloadFilter({queryFunc, loading}) {
    const dispatch = useDispatch();

    const { dateRange } = useSelector((state) => state.downloadFilter);

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    const {
        user: { employeeLevel },
    } = useSelector((state) => state.auth);

    return (
        <>
            <DateRange  dataPickerFunc={dataPickerFunc} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    placeholder="Report Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(v) => dispatch(reportType(v))}
                    options={
                        [
                            
                            {
                                label: 'FF Visit Call',
                                value: 'FF Visit Call',
                            },
                            {
                                label: 'MTCM Visit Call',
                                value: 'MTCM Visit Call',
                            },
                            {
                                label: 'Display Audit AI Result',
                                value: 'Display Audit AI Result',
                            },
                            {
                                label: 'CM SUP Joint And Control Call',
                                value: 'CM SUP Joint And Control Call',
                            },
                            {
                                label: 'Outlet wise FAT',
                                value: 'Outlet wise FAT',
                            },
                            {
                                label: 'SOS AI Result',
                                value: 'SOS AI Result',
                            },
                            {
                                label: 'QPDS AI Result',
                                value: 'QPDS AI Result',
                            },
                            {
                                label: 'POSM AI Result',
                                value: 'POSM AI Result',
                            },
                            {
                                label: 'POSM Used Report',
                                value: 'POSM Used Report',
                            }, 
                            {
                                label: 'Attendance Report',
                                value: 'Attendance Report',
                            },
                            {
                                label: 'Leave Report',
                                value: 'Leave Report',
                            },
                            {
                                label: 'Strike Rate Report',
                                value: 'Strike Rate Report',
                            },
                            {
                                label: 'Beauty Store Display Compliance Report',
                                value: 'Beauty Store Display Compliance Report',
                            },
                            {
                                label: 'Nutrition Store Display Compliance Report',
                                value: 'Nutrition Store Display Compliance Report',
                            },
                            {
                                label: 'Masterdata',
                                value: 'Masterdata',
                            },
                        ]
                        }
                />
            </Col>
            
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <FilterButton loading={loading} fn={queryFunc} icon={<CloudDownloadOutlined />} title="Export Data" />
            </Col>
        </>
    );
}

export default DownloadFilter;
